import { ActionTree } from "vuex";
import { AxiosResponse } from "axios";

import StructureService from "@/app/shared/services/structure.service";

import { RootState } from "@/app/shared/state/config/types";
import { DocumentState } from "./types";

import Structure from "@/app/domains/document/models/structure.model";

export const actions: ActionTree<DocumentState, RootState> = {
  getStructure(_, id: number): Promise<AxiosResponse<Structure>> {
    return StructureService.getStructureById(id);
  },
};
