import { MutationTree } from "vuex";
import Project from "@/app/shared/models/project.model";
import { ProjectsState } from "./types";

export const mutations: MutationTree<ProjectsState> = {
  setProjects(state, projects: Array<Project>): void {
    state.projects = projects;
  },

  setSelectedProject(state, project: Project): void {
    state.selectedProject = project;
  },
};
