import Http from "@/app/shared/http";
import { HttpConfig } from "@/app/shared/http/http";
import { AxiosResponse } from "axios";

import Document from "@/app/domains/document/models/document.model";
import SavedDocument from "@/app/domains/document/models/saved-document.model";
import PublishModel from "@/app/domains/document/models/publish.model";

export const getDocuments = (): Promise<AxiosResponse<Array<SavedDocument>>> =>
  Http.request<Array<SavedDocument>>(
    new HttpConfig({
      url: "Document",
      method: "get",
    })
  );

export const getUnusedDocuments = (
  projectId: number
): Promise<AxiosResponse<Array<Document>>> =>
  Http.request<Array<Document>>(
    new HttpConfig({
      url: `Document/GetUnused/${projectId}`,
      method: "get",
    })
  );

export const getDocumentById = (
  id: number
): Promise<AxiosResponse<SavedDocument>> =>
  Http.request<SavedDocument>(
    new HttpConfig({
      url: `Document/${id}`,
      method: "get",
    })
  );

export const saveDocument = (
  data: FormData
): Promise<AxiosResponse<Document>> =>
  Http.request<Document>(
    new HttpConfig({
      url: "Document/Save",
      method: "post",
      data,
    })
  );

export const publishDocument = (
  data: PublishModel
): Promise<AxiosResponse<void>> =>
  Http.request<void>(
    new HttpConfig({
      url: "Document/Publish",
      method: "post",
      data,
    })
  );

export const removeLinkBetweenDocumentAndProject = (
  documentId: number,
  projectId: number
): Promise<AxiosResponse<void>> =>
  Http.request<void>(
    new HttpConfig({
      url: `Document/${documentId}/FromProject/${projectId}`,
      method: "delete",
    })
  );

export const deleteDocumentById = (
  documentId: number
): Promise<AxiosResponse<void>> =>
  Http.request<void>(
    new HttpConfig({
      url: `/Document/${documentId}`,
      method: "delete",
    })
  );

export default {
  getDocuments,
  getUnusedDocuments,
  getDocumentById,
  saveDocument,
  publishDocument,
  removeLinkBetweenDocumentAndProject,
  deleteDocumentById,
};
