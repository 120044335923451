import Vue from "vue";
import "./assets/element/index.css";

import {
  Button,
  Dialog,
  Input,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Popover,
  Upload,
  Row,
  Col,
  InputNumber,
  Form,
  FormItem,
  Tooltip,
  Checkbox,
  Select,
  Option,
  MessageBox,
  Message,
  Tag,
} from "element-ui";
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Popover);
Vue.use(Upload);
Vue.use(Row);
Vue.use(Col);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tooltip);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tag);

Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
