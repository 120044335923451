import { MutationTree } from "vuex";
import { ConfigState } from "./types";
import StorageTypes from "@/app/shared/types/storage.types";
import StorageService from "@/app/shared/services/storage.service";

export const mutations: MutationTree<ConfigState> = {
  setToken(state, value: string | null) {
    state.token = value;
    StorageService.setItem(StorageTypes.TOKEN, value || "");
  },

  setUserName(state, value: string | null) {
    state.userName = value;
    if (value) {
      StorageService.setItem(StorageTypes.USER_NAME, value);
    } else StorageService.removeItem(StorageTypes.USER_NAME);
  },

  setRoles(state, roles: Array<string>) {
    state.roles = roles;
  },
};
