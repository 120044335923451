import { Module } from "vuex";
import { RootState } from "@/app/shared/state/config/types";
import { DocumentState } from "./types";
import { state } from "./state";
import { actions } from "./actions";

export const documentModule: Module<DocumentState, RootState> = {
  namespaced: true,
  state,
  actions,
};
