import Vue from "vue";
import Vuex from "vuex";
import { configModule } from "@/app/shared/state/config";
import { settingsModule } from "@/app/domains/settings/state/index";
import { documentModule } from "@/app/domains/document/state/index";
import { projectsModule } from "@/app/domains/projects/state/index";
// import ComponentCustomProperties from "vue";

declare module "vuex" {
  // declare your own store states
  interface State {
    count: number;
  }

  // provide typings for `this.$store`
  interface ComponentCustomProperties {
    $store: Store<State>;
  }
}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    configModule,
    settingsModule,
    documentModule,
    projectsModule,
  },
});
