import { RouteConfig } from "vue-router";
import store from "@/app/app.store";
import NotFoundRoute from "@/app/shared/views/not-found/not-found.route";

const children: Array<RouteConfig> = [
  {
    path: "projects",
    name: "settings-projects",
    component: () =>
      import("@/app/domains/settings/views/projects/projects.vue"),
    children: [
      {
        path: ":settingsProject",
        name: "settings-project-selected",
        component: () =>
          import("@/app/domains/settings/views/projects/project.vue"),
      },
    ],
  },
  {
    path: "users",
    name: "settings-users",
    component: () => import("@/app/domains/settings/views/users/users.vue"),
    beforeEnter(to, from, next): void {
      if (store.getters["configModule/roles"].includes("ConfigurationUsers"))
        next();
      else next({ name: NotFoundRoute.name });
    },
    children: [
      {
        path: ":userId",
        name: "settings-user-selected",
        component: () => import("@/app/domains/settings/views/users/user.vue"),
      },
    ],
  },
  {
    path: "articles",
    name: "articles",
    component: () =>
      import("@/app/domains/settings/views/articles/articles.vue"),
  },
  {
    path: "media",
    name: "media",
    component: () => import("@/app/domains/settings/views/media/media.vue"),
    beforeEnter(to, from, next): void {
      if (store.getters["configModule/roles"].includes("ConfigurationMedia"))
        next();
      else next({ name: NotFoundRoute.name });
    },
  },
];

const settingsRoute: RouteConfig = {
  path: "/settings",
  name: "settings",
  redirect: { name: children[0].name },
  component: () => import("@/app/domains/settings/settings.vue"),
  children,
};

export default settingsRoute;
