import { ActionTree } from "vuex";
import { AxiosResponse } from "axios";
import ProjectService from "@/app/shared/services/project.service";

import { RootState } from "@/app/shared/state/config/types";
import { ProjectsState } from "./types";
import Project from "@/app/shared/models/project.model";

export const actions: ActionTree<ProjectsState, RootState> = {
  async getProjects({ commit }): Promise<void> {
    const projects = (await ProjectService.getProjects()).data;
    commit("setProjects", projects);
  },

  getProjectById(_, id: number): Promise<AxiosResponse<Project>> {
    return ProjectService.getProjectById(id);
  },
};
