import { ActionTree } from "vuex";
import AccountService from "@/app/shared/services/account.service";
import { ConfigState, RootState } from "./types";
import { AxiosResponse } from "axios";
import Token from "../../models/token.model";

export const actions: ActionTree<ConfigState, RootState> = {
  logout({ commit }): void {
    commit("setToken", null);
    commit("setUserName", null);
    AccountService.logout();
  },

  async getToken(
    { commit },
    loginForm: { login: string; password: string }
  ): Promise<AxiosResponse<Token>> {
    const response = await AccountService.getToken(loginForm);
    commit("setToken", response.data.token);
    commit("setUserName", response.data.userName);
    return response;
  },

  async getUserRoles({ commit }): Promise<AxiosResponse<Array<string>>> {
    const response = await AccountService.getUserRoles();
    commit("setRoles", response.data);
    return response;
  },
};
