import Http from "@/app/shared/http";
import { HttpConfig } from "@/app/shared/http/http";
import { AxiosResponse } from "axios";

import Login from "@/app/shared/models/login.model";
import Token from "@/app/shared/models/token.model";
import User from "@/app/domains/settings/models/user.model";
import UserDetails from "@/app/domains/settings/models/user-details.model";

import StorageTypes from "@/app/shared/types/storage.types";
import StorageService from "@/app/shared/services/storage.service";

export const createUser = (user: UserDetails): Promise<AxiosResponse<User>> =>
  Http.request<User>(
    new HttpConfig({
      url: "Account",
      method: "post",
      data: user,
    })
  );

export const updateUser = (user: UserDetails): Promise<AxiosResponse<void>> =>
  Http.request<void>(
    new HttpConfig({
      url: "Account",
      method: "put",
      data: user,
    })
  );

export const deleteUser = (userId: string): Promise<AxiosResponse<void>> =>
  Http.request<void>(
    new HttpConfig({
      url: `Account/${userId}`,
      method: "delete",
    })
  );

export const getUser = (id: string): Promise<AxiosResponse<User>> =>
  Http.request<User>(
    new HttpConfig({
      url: "Account/" + id,
      method: "get",
    })
  );

export const getUsers = (): Promise<AxiosResponse<Array<User>>> =>
  Http.request<Array<User>>(
    new HttpConfig({
      url: "Account",
      method: "get",
    })
  );

export const getAllRoles = (): Promise<AxiosResponse<Array<string>>> =>
  Http.request<Array<string>>(
    new HttpConfig({
      url: "Account/roles",
      method: "get",
    })
  );

export const check = (): Promise<AxiosResponse<boolean>> =>
  Http.request<boolean>({
    config: {
      url: "Account/Check",
      method: "get",
    },
  });

export const getToken = (data: Login): Promise<AxiosResponse<Token>> =>
  Http.request<Token>(
    new HttpConfig({
      url: "Account/token",
      method: "post",
      data,
    })
  );

export const getUserRoles = (): Promise<AxiosResponse<Array<string>>> =>
  Http.request<Array<string>>(
    new HttpConfig({
      url: "Account/UserRoles",
      method: "get",
    })
  );

export const logout = (): void => {
  localStorage.removeItem(StorageTypes.TOKEN);
};

export const isAuth = (): boolean =>
  !!StorageService.getItem(StorageTypes.TOKEN);

export default {
  createUser,
  updateUser,
  deleteUser,
  getUser,
  getUsers,
  getAllRoles,
  check,
  getToken,
  isAuth,
  logout,
  getUserRoles,
};
