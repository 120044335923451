













































import { Component, Vue } from "vue-property-decorator";

import LoginRoute from "@/app/shared/views/login/login.route";
import { CreateDocumentRoute } from "@/app/domains/document/document.route";

import Navigation from "@/app/shared/components/navigation/navigation.vue";
import Dialog from "@/app/shared/components/dialog/dialog.vue";

import Project from "@/app/shared/models/project.model";

@Component({
  components: {
    Navigation,
    Dialog,
  },
})
export default class Header extends Vue {
  private get showHeader(): boolean {
    return this.$store.getters["configModule/isAuth"];
  }

  private get url(): string {
    const [firstProject] = this.projects;
    let project = this.selectedProject || firstProject;
    return "/projects/" + (project ? project.id : "");
  }

  public get userName(): string {
    return this.$store.getters["configModule/userName"];
  }

  private get selectedProject(): Project {
    return this.$store.getters["projectsModule/getSelectedProject"];
  }

  private get projects(): Array<Project> {
    return this.$store.getters["projectsModule/projects"];
  }

  private get showCreate(): boolean {
    return this.$route.name !== CreateDocumentRoute.name;
  }

  private get createDocumentPath(): {
    name?: string;
    params: { [key: string]: string | undefined };
  } {
    return {
      name: CreateDocumentRoute.name,
      params: { projectId: this.selectedProject?.id?.toString() },
    };
  }

  private selectMenuItem(action: string): void {
    switch (action) {
      case "logout":
        this.$store.dispatch("configModule/logout");
        this.$router.push({ name: LoginRoute.name });
        break;

      default:
        break;
    }
  }
}
