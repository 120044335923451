import { RouteConfig } from "vue-router";
import store from "@/app/app.store";

const loginRoute: RouteConfig = {
  path: "/login",
  name: "login",
  beforeEnter: (to, from, next) => {
    if (store.getters["configModule/isAuth"]) {
      next(false);
      return;
    }
    next();
  },
  component: () => import("@/app/shared/views/login/login.vue"),
};

export default loginRoute;
