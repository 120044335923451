import { RouteConfig } from "vue-router";
import LoginRoute from "@/app/shared/views/login/login.route";
import store from "@/app/app.store";

const notFoundRoute: RouteConfig = {
  path: "*",
  name: "not-found",
  beforeEnter: (to, from, next) => {
    if (!store.getters["configModule/isAuth"]) {
      next({ name: LoginRoute.name });
      return;
    }
    next();
  },
  component: () => import("@/app/shared/views/not-found/not-found.vue"),
};

export default notFoundRoute;
