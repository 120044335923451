






















import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class Dialog extends Vue {
  @Prop({ required: true, default: false }) dialogVisible = false;
  @Prop({ type: Boolean }) appendToBody!: boolean;
  @Prop({ type: Boolean }) destroyOnClose!: boolean;
  @Prop({ type: String }) width!: string;

  @Emit()
  open(): void {
    return;
  }

  @Emit()
  close(): void {
    return;
  }

  @Emit()
  closed(): void {
    return;
  }
}
