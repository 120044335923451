import Vue from "vue";
import "./assets/style.scss";
import "./element";
import "./error.handler";

import { Component } from "vue-property-decorator";
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import StorageService from "@/app/shared/services/storage.service";
import StorageTypes from "@/app/shared/types/storage.types";

import App from "@/app/app.vue";
import store from "@/app/app.store";
import { createRouter } from "@/app/app.router";
const router = createRouter(store);

console.log(
  "%cVersion: 205",
  `background-color: rebeccapurple; color: white; padding: 5px;`
);
Vue.config.productionTip = false;

async function app() {
  const token = StorageService.getItem(StorageTypes.TOKEN);
  if (token) {
    await store.dispatch("configModule/getUserRoles");
  }
  new Vue({
    router,
    store,
    render: (h) => h(App),
    async beforeCreate() {
      const userName = StorageService.getItem(StorageTypes.USER_NAME);
      if (token) store.commit("configModule/setToken", token);
      if (userName) store.commit("configModule/setUserName", userName);
    },
  }).$mount("#app");
}

app();
