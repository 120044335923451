import { GetterTree } from "vuex";
import { RootState } from "@/app/shared/state/config/types";
import User from "@/app/domains/settings/models/user.model";
import { SettingsState } from "./types";

export const getters: GetterTree<SettingsState, RootState> = {
  users(state): Array<User> {
    return state.users;
  },
};
