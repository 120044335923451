import { GetterTree } from "vuex";
import AccountService from "@/app/shared/services/account.service";
import { ConfigState, RootState } from "./types";
import { state } from "./state";

export const getters: GetterTree<ConfigState, RootState> = {
  isAuth(state): boolean {
    return !!state.token || AccountService.isAuth();
  },

  userName(state): string | null {
    return state.userName;
  },

  roles(): Array<string> {
    return state.roles;
  },
};
