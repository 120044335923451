import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { Store } from "vuex";

// public routes
import LoginRoute from "@/app/shared/views/login/login.route";
import NotFoundRoute from "@/app/shared/views/not-found/not-found.route";

// private routes
import ProjectsRoute from "@/app/domains/projects/project.route";
import DocumentRoute from "@/app/domains/document/document.route";
import SettingsRoute from "@/app/domains/settings/settings.route";

Vue.use(VueRouter);

let router: VueRouter;

const privateRoutes: Array<RouteConfig> = [
  ProjectsRoute,
  DocumentRoute,
  SettingsRoute,
];

function hasPrivateRoutes(): boolean {
  return router.getRoutes().length > privateRoutes.length;
}

export function giveAccessToPrivateRoutes(store: Store<any>): void {
  if (!store.getters["configModule/isAuth"] || hasPrivateRoutes()) return;
  try {
    privateRoutes.forEach((route) => router.addRoute(route));
  } catch (error) {
    console.error(error);
  }
}

function createRouter(store: Store<any>) {
  const isAuth = (): boolean => store.getters["configModule/isAuth"];

  const routes: Array<RouteConfig> = [
    {
      path: "/",
      beforeEnter: (to, from, next) => {
        if (!isAuth()) {
          next({ name: LoginRoute.name });
          return;
        }
        next({ name: ProjectsRoute.name });
      },
    },
    LoginRoute,
    NotFoundRoute,
  ];

  router = new VueRouter({
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    if (!isAuth() && to.name !== LoginRoute.name) {
      next({ name: LoginRoute.name });
      return;
    }
    next();
  });

  giveAccessToPrivateRoutes(store);

  return router;
}

export { createRouter, router };
