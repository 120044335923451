import { ActionTree } from "vuex";
import { AxiosResponse } from "axios";

import AccountService from "@/app/shared/services/account.service";
import DocumentService from "@/app/shared/services/document.service";
import StructureService from "@/app/shared/services/structure.service";
import ProjectService from "@/app/shared/services/project.service";

import Structure from "@/app/domains/document/models/structure.model";
import Document from "@/app/domains/document/models/document.model";
import UserDetails from "@/app/domains/settings/models/user-details.model";
import Project from "@/app/shared/models/project.model";
import User from "@/app/domains/settings/models/user.model";

import { RootState } from "@/app/shared/state/config/types";
import { SettingsState } from "./types";

export const actions: ActionTree<SettingsState, RootState> = {
  getUsers(): Promise<AxiosResponse<User[]>> {
    return AccountService.getUsers();
  },

  getUser(_, id: string): Promise<AxiosResponse<User>> {
    return AccountService.getUser(id);
  },

  getAllRoles(): Promise<AxiosResponse<Array<string>>> {
    return AccountService.getAllRoles();
  },

  updateUser(_, user: UserDetails): Promise<AxiosResponse<void>> {
    return AccountService.updateUser(user);
  },

  deleteUser(_, userId: string): Promise<AxiosResponse<void>> {
    return AccountService.deleteUser(userId);
  },

  getDocuments(): Promise<AxiosResponse<Document[]>> {
    return DocumentService.getDocuments();
  },

  getUnusedDocuments(_, projectId: number): Promise<AxiosResponse<Document[]>> {
    return DocumentService.getUnusedDocuments(projectId);
  },

  modifyStructure(
    _,
    { structure, projectId }: { structure: Structure; projectId: number }
  ): Promise<AxiosResponse<void>> {
    return StructureService.modifyStructure(structure, projectId);
  },

  createProject(_, project: Project): Promise<AxiosResponse<void>> {
    return ProjectService.createProject(project);
  },

  removeProject(_, id: number): Promise<AxiosResponse<void>> {
    return ProjectService.removeProject(id);
  },
};
