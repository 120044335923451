import { RouteConfig } from "vue-router";

export const CreateDocumentRoute: RouteConfig = {
  path: "create/:projectId/:parentId?",
  name: "create-document",
  component: () => import("@/app/domains/document/modify-document.vue"),
};

export const EditDocumentRoute: RouteConfig = {
  path: "edit/:projectId/:id",
  name: "edit-document",
  component: () => import("@/app/domains/document/modify-document.vue"),
};

const DocumentRoute: RouteConfig = {
  path: "/documents",
  name: "document-container",
  component: () => import("@/app/domains/document/document-container.vue"),
  children: [CreateDocumentRoute, EditDocumentRoute],
};

export default DocumentRoute;
