




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop({ default: 12, type: Number }) height!: number;
  @Prop({ default: 30, type: Number }) width!: number;

  get style(): { "min-width": string; height: string } {
    return { "min-width": this.width + "px", height: this.height + "px" };
  }
}
