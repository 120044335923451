import { Loading } from "element-ui";

import axios, {
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { ElLoadingComponent } from "element-ui/types/loading";

interface IHttpIntermediate {
  (httpConfig: IHttpConfig): void;
}

interface IHttpConfig {
  config: AxiosRequestConfig;
  showLoader?: boolean;
  blurOnRequest?: boolean;
  // callbacks
  success?: (response: AxiosResponse) => void;
  error?: (error: AxiosError) => void;
  before?: IHttpIntermediate;
}

export class HttpConfig implements IHttpConfig {
  constructor(config: AxiosRequestConfig) {
    this.config = config || {};
  }

  config: AxiosRequestConfig = {};
  showLoader = true;
  blurOnRequest = false;
  // callbacks
  before = (httpConfig: IHttpConfig): void => {
    // if someone pressed enter for a long time,
    // remove focus from focused element
    if (httpConfig.blurOnRequest)
      (<HTMLElement>document?.activeElement)?.blur();
  };
}

export class Http {
  private _baseUrl = "";
  private _axios?: AxiosInstance;

  constructor(baseUrl: string) {
    this._baseUrl = baseUrl;
    this.createHttp();
  }

  private get $_axios(): AxiosInstance {
    if (this._axios) return this._axios;
    return this.createHttp();
  }

  private createHttp() {
    this._axios = axios.create({ baseURL: this._baseUrl });
    this._axios.defaults.withCredentials = true;
    this._axios.defaults.headers.post["Content-Type"] = "application/json";
    return this._axios;
  }

  registerRequestInterceptor(
    func: (
      config: AxiosRequestConfig
    ) => AxiosRequestConfig | Promise<AxiosRequestConfig>
  ): void {
    this.$_axios.interceptors.request.use(func);
  }

  registerResponsetInterceptor(
    func: (config: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
    errorFunc?: (config: AxiosError) => AxiosError | Promise<AxiosError>
  ): void {
    this.$_axios.interceptors.response.use(func, errorFunc);
  }

  async request<T>(httpConfig: IHttpConfig): Promise<AxiosResponse<T>> {
    if (httpConfig.before) httpConfig.before(httpConfig as HttpConfig);

    let loader: ElLoadingComponent | undefined;

    const timer = setTimeout(() => {
      if (httpConfig.showLoader) {
        loader = Loading.service({ lock: true });
      }
    }, 300);

    try {
      const result = await this.$_axios(httpConfig.config);
      if (httpConfig.success) httpConfig.success(result);
      return result;
    } catch (error) {
      if (httpConfig.error) httpConfig.error(error as AxiosError);
      throw error;
    } finally {
      if (loader) loader.close();
      clearInterval(timer);
    }
  }
}
