import Http from "@/app/shared/http";
import { HttpConfig } from "@/app/shared/http/http";
import Project from "@/app/shared/models/project.model";
import { AxiosResponse } from "axios";

export const createProject = (project: Project): Promise<AxiosResponse<void>> =>
  Http.request<void>(
    new HttpConfig({
      url: "Project",
      method: "post",
      data: project,
    })
  );

export const removeProject = (id: number): Promise<AxiosResponse<void>> =>
  Http.request<void>(
    new HttpConfig({
      url: `Project/${id}`,
      method: "delete",
    })
  );

export const getProjects = (): Promise<AxiosResponse<Array<Project>>> =>
  Http.request<Array<Project>>(
    new HttpConfig({
      url: "Project",
      method: "get",
    })
  );

export const getProjectById = (id: number): Promise<AxiosResponse<Project>> =>
  Http.request<Project>(
    new HttpConfig({
      url: "Project/" + id,
      method: "get",
    })
  );

export default {
  createProject,
  removeProject,
  getProjects,
  getProjectById,
};
