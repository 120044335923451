




































import { Component, Vue, Watch } from "vue-property-decorator";
import Skeleton from "@/app/shared/components/skeleton/skeleton.vue";

import Project from "@/app/shared/models/project.model";

@Component({
  components: {
    Skeleton,
  },
})
export default class Navigator extends Vue {
  private get project(): Project {
    return this.$store.getters["projectsModule/getSelectedProject"];
  }

  private get projects(): Array<Project> {
    return this.$store.getters["projectsModule/projects"];
  }

  private setSelectedProject(project: Project): void {
    this.$store.commit("projectsModule/setSelectedProject", project);
  }

  private changeProject(project: Project): void {
    this.setSelectedProject(project);
    this.goToSelectedProject();
  }

  private goToSelectedProject(): void {
    if (
      this.project &&
      this.project.id &&
      parseInt(this.$route.params.project) !== this.project?.id &&
      this.$route.name &&
      ["projects", "selected-project", "read-document", "login"].includes(
        this.$route.name
      )
    ) {
      this.$router
        .push({
          name: "selected-project",
          params: { project: this.project.id.toString() },
        })
        .catch((error) => error);
    }
  }

  private getProjectById(id: number): Project | undefined {
    return this.projects.find((project) => project.id === id);
  }

  @Watch("$route.params.project")
  urlChange(newVal: string, oldValue: string): void {
    if (newVal && newVal !== oldValue) {
      const selectedProject = this.getProjectById(parseInt(newVal));
      if (selectedProject) this.changeProject(selectedProject);
    }
  }

  async created(): Promise<void> {
    await this.$store.dispatch("projectsModule/getProjects");
    if (this.projects.length) {
      const project =
        this.getProjectById(parseInt(this.$route.params.project)) ||
        this.projects[0];
      this.setSelectedProject(project);
      this.goToSelectedProject();
    }
  }
}
