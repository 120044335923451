import StorageService from "@/app/shared/services/storage.service";
import StorageTypes from "@/app/shared/types/storage.types";
import LoginRoute from "@/app/shared/views/login/login.route";
import store from "@/app/app.store";
import { router } from "@/app/app.router";
import { Http } from "./http";

const path =
  process.env.NODE_ENV === "production"
    ? "/api"
    : `${process.env.VUE_APP_URL}/api`;
    
const http = new Http(path);

http.registerRequestInterceptor((config) => {
  const token = StorageService.getItem(StorageTypes.TOKEN);
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

http.registerResponsetInterceptor(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch("configModule/logout");
      router.push({ name: LoginRoute.name }).catch((error) => error);
    }

    return Promise.reject(error);
  }
);

export default http;
