import { GetterTree } from "vuex";
import Project from "@/app/shared/models/project.model";
import { RootState } from "@/app/shared/state/config/types";
import { ProjectsState } from "./types";

export const getters: GetterTree<ProjectsState, RootState> = {
  projects(state): Array<Project> {
    return state.projects;
  },

  getSelectedProject(state): Project | null {
    return state.selectedProject;
  },
};
