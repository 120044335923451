import { RouteConfig } from "vue-router";

const projectsRoute: RouteConfig = {
  path: "/projects",
  name: "projects",
  component: () => import("@/app/domains/projects/projects.vue"),
  children: [
    {
      path: ":project",
      name: "selected-project",
      component: () => import("@/app/domains/projects/views/project.vue"),
      children: [
        {
          path: ":id",
          name: "read-document",
          component: () =>
            import("@/app/domains/projects/views/read-document.vue"),
        },
      ],
    },
  ],
};

export default projectsRoute;
