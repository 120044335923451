import Http from "@/app/shared/http";
import { HttpConfig } from "@/app/shared/http/http";
import Structure from "@/app/domains/document/models/structure.model";
import { AxiosResponse } from "axios";

export const getStructure = (): Promise<AxiosResponse<Structure>> =>
  Http.request<Structure>(
    new HttpConfig({
      url: "Structure",
      method: "get",
    })
  );

export const getStructureById = (
  id: number
): Promise<AxiosResponse<Structure>> =>
  Http.request<Structure>(
    new HttpConfig({
      url: "Structure/" + id,
      method: "get",
    })
  );

export const modifyStructure = (
  data: Structure,
  projectId: number
): Promise<AxiosResponse<void>> =>
  Http.request<void>(
    new HttpConfig({
      url: `Structure/${projectId}`,
      method: "post",
      data,
    })
  );

export const updateStructure = (
  data: Structure
): Promise<AxiosResponse<Structure>> =>
  Http.request<Structure>(
    new HttpConfig({
      url: "Structure",
      method: "put",
      data,
    })
  );

export const deleteStructure = (
  id: number
): Promise<AxiosResponse<Structure>> =>
  Http.request<Structure>(
    new HttpConfig({
      url: "Structure/" + id,
      method: "delete",
    })
  );

export default {
  getStructureById,
  getStructure,
  modifyStructure,
  updateStructure,
  deleteStructure,
};
